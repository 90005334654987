// Training.js

import React from 'react';
import Header from './Header'; // assuming Header is the navigation bar component
import Footer from './Footer';
import './css/Training.css'; // make sure the CSS is linked correctly
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from './CheckoutForm';
import { loadStripe } from "@stripe/stripe-js";

//const promise = loadStripe("pk_test_51HHMFBLb5KZXfDYgLFQAetYb7cGGuiegoT1Sjm9RE8FSyLA5QgYmlvJ2lkBlPVhQAkm3kVQHsbziLS18WztEybFy00SiEfx3Mm");
const promise = loadStripe("pk_live_51HHMFBLb5KZXfDYguK930v3O6ydugI72ThwFDVzIaKdqIVVOsmUDEHJCBm9E6eJAeLGSjvTNmdXNYLXfD9Q9G8R0005gh9V8zN");
class Training extends React.Component {
  render() {
    return (
      <div>
        <Header />
            <div className="title-container">
          <h1 className="research-title"><u className="underline">Training</u> Workshops</h1>
          <p className="training-intro">
          Are you a mental health practitioner that wants to extend your existing approach or extend what you offer your clients? Are you a coach looking to make a bigger, more direct impact on your clients? There are monthly sessions available that teach the basics of CRT and empower you to get started with implementing CRT into your practice immediately. Find a session below and join in! Formal certifications with more extensive training are coming soon!          
          </p>
          </div>
          <div className="workshop-container">
            {/* Repeat this section for each workshop */}
            <section className="workshop-box">
              <div className="workshop-details">
                <h2>Cognitive Response Therapy Introduction</h2>
                <p>In this introductory workshop, attendees will be introduced to the fundamentals of CRT including all key concepts, the research that drives CRT, and specific actionable approaches that can be used immediately in a current practice.</p>
                <ul className="workshop-bullets">
                  <li><i className="checkmark">✓</i> Price: $499</li>
                  <li><i className="checkmark">✓</i> Date: Saturday 8/17</li>
                  <li><i className="checkmark">✓</i> Time: 9AM PST</li>
                  <li><i className="checkmark">✓</i> Format: Online</li>
                  <li><i className="checkmark">✓</i> Duration: 3 hours</li>
                </ul>
              </div>
              <div className="workshop-payment">
                <h3>Workshop is full. Please check future dates.</h3>
              <Elements stripe={promise}>
                        <CheckoutForm amount={49900} total={499} />
                      </Elements>
              </div>
            </section>
            <section className="workshop-box">
              <div className="workshop-details">
                <h2>LACS Deep Dive</h2>
                <p>In this engaging workshop, attendees will be brought through the details of how the Language Association and Categorization System is created, explore the power of it, and learn how to leverage it in various ways to help their clients.</p>
                <ul className="workshop-bullets">
                  <li><i className="checkmark">✓</i> Price: $399</li>
                  <li><i className="checkmark">✓</i> Date: Sunday 8/18</li>
                  <li><i className="checkmark">✓</i> Time: 9AM PST</li>
                  <li><i className="checkmark">✓</i> Format: Online</li>
                  <li><i className="checkmark">✓</i> Duration: 3 hours</li>
                </ul>
              </div>
              <div className="workshop-payment">
              <Elements stripe={promise}>
                        <CheckoutForm amount={39900} total={399} />
                      </Elements>
              </div>
            </section>
            <section className="workshop-box">
              <div className="workshop-details">
                <h2>Cognitive Response Therapy Introduction</h2>
                <p>In this introductory workshop, attendees will be introduced to the fundamentals of CRT including all key concepts, the research that drives CRT, and specific actionable approaches that can be used immediately in a current practice.</p>
                <ul className="workshop-bullets">
                  <li><i className="checkmark">✓</i> Price: $499</li>
                  <li><i className="checkmark">✓</i> Date: Saturday 8/31</li>
                  <li><i className="checkmark">✓</i> Time: 9AM PST</li>
                  <li><i className="checkmark">✓</i> Format: Online</li>
                  <li><i className="checkmark">✓</i> Duration: 3 hours</li>
                </ul>
              </div>
              <div className="workshop-payment">
              <Elements stripe={promise}>
                        <CheckoutForm amount={49900} total={499} />
                      </Elements>
              </div>
            </section>
            <section className="workshop-box">
              <div className="workshop-details">
                <h2>Deep Dive into Personas, Identities, and Core Disharmonies</h2>
                <p>Through this empowering workshop, attendees will learn one of the root causes to negative Predictive Response Cycles, Core Disharmonies and the cause of them. Attendees will be brought through how to help people to recognize the internal dissonance and specific actionable steps that can be taken to resolve them, increasing the person's subjective well being and improving their mental health.</p>
                <ul className="workshop-bullets">
                  <li><i className="checkmark">✓</i> Price: $399</li>
                  <li><i className="checkmark">✓</i> Date: Sunday 9/1</li>
                  <li><i className="checkmark">✓</i> Time: 9AM PST</li>
                  <li><i className="checkmark">✓</i> Format: Online</li>
                  <li><i className="checkmark">✓</i> Duration: 3 hours</li>
                </ul>
              </div>
              <div className="workshop-payment">
              <Elements stripe={promise}>
                        <CheckoutForm amount={39900} total={399} />
                      </Elements>
              </div>
            </section>
                      </div>
        <Footer />
      </div>
    );
  }
}

export default Training;
