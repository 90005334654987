import React, { useState, useEffect } from "react";
import './css/stripe.css';
import axios from 'axios';
import {APIHost} from './GlobalVariables';
import { Link, Redirect } from 'react-router-dom';

import {
  CardElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";

const ServerFailure = () => (
<h2><font color="red">We apologize. An error occurred while retrieving information from our server. Please refresh the page and try again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { loggedIn: true } }}>here</Link> and let us know.</font></h2>
);

const CreditUpdateFailure = () => (
<h2><font color="red">We apologize. An error occurred while updating the number of credits for you. Support has been automatically contacted and will rectify as soon as possible.<br />
If you have any questions or concerns, please reach out to support <Link to={{ pathname: '/Contact', state: { loggedIn: true } }}>here</Link> and let us know.</font></h2>
);

const SuccessPayment = () => (
  <h2><font color="blue">Thank you for your purchase!</font></h2>
  );

export default function CheckoutForm(props) {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState('');
  const [email, setEmail] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [paymentID, setPaymentID] = useState(null);
  const [server_failure, setServerFailure] = useState(false);
  const [name, setName] = useState('');
  const [credit_update_failure, setCreditUpdateFailure] = useState(false);
  const [total, setTotal] = useState(props.total);
  const [amount, setAmount] = useState(props.amount);
 
  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  };
  const stripe = useStripe();
  const elements = useElements();

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const handleSubmit = async ev => {
    console.log("It is " + amount);
   ev.preventDefault();
    setProcessing(true);

   axios.post(APIHost + '/api/createPaymentIntent', {
      withCredentials: true,
      amount: 49999
    }).then(async (res) => {
        setClientSecret(res.data.clientSecret);
        setPaymentID(res.data.paymentID);
       const payload = await stripe.confirmCardPayment(res.data.clientSecret, {
          receipt_email: email,
          payment_method: {
            card: elements.getElement(CardElement),
            billing_details: {
              name: name
            }
          }
        });
        if (payload.error) {
          setError(`Payment failed ${payload.error.message}`);
          setProcessing(false);
        } else {
          setSucceeded(true);
     
        }
    }).catch((error) => {
      console.log("Error is " + error);
      setServerFailure(true);
    });

  };

  return (
          <div className="CheckoutForm">

    <form id="payment-form" onSubmit={handleSubmit}>
        <p>Total: ${total}</p>
      <input
        type="text"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Enter email address"
      />
      <input
        type="text"
        value={name}
        onChange={(n) => setName(n.target.value)}
        placeholder="Name on card"
      />
      <CardElement id="card-element" options={cardStyle} onChange={handleChange} />
      <button
        disabled={processing || succeeded}
        id="submit"
      >
        <span id="button-text">
          {processing ? (
            <div className="spinner" id="spinner"></div>
          ) : (
            "Purchase"
          )}
        </span>
      </button>
      {/* Show any error that happens when processing the payment */}
      {error && (
        <div className="card-error" role="alert">
          {error}
        </div>
      )}
      {/* Show a success message upon completion */}
      <p className={succeeded ? "result-message" : "result-message hidden"}>
        Thank you for your purchase!
      </p>
      {server_failure && <ServerFailure />}
      {credit_update_failure && <CreditUpdateFailure />}
    </form>

      </div>
  );
}
