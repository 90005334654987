// Header.js

import React, { Component } from 'react';
import './css/Header.css'; // Make sure to create a Header.css file with your styles
import logo from './images/crt-high-resolution-logo-transparent.png';
class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isMenuOpen: false
        }
        this.toggleMenu = this.toggleMenu.bind(this);
    }
    componentDidMount() {
		window.scroll(0, 0);
	}  

    toggleMenu() {
        this.setState({isMenuOpen: !this.state.isMenuOpen});
    }
  render() {
    return (
        <header className="header">
        <div className="main-nav">
        <nav className={`nav ${this.state.isMenuOpen ? 'open' : ''}`}>
        <a href="/" className="nav-link">Home</a>
          <a href="/Training" className="nav-link">Training</a>
          <a href="/programs" className="nav-link">Programs</a>
          <a href="/Research" className="nav-link">Research</a>
          <a href="/About" className="nav-link">About</a>
          <a href="/Providers" className="nav-link">Providers</a>
          <a href="/Contact" className="nav-link">Contact</a>
        </nav>
        <div className="hamburger" onClick={this.toggleMenu}>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
        </div>
      </header>
    
    );
  }
}

export default Header;
