
import React, { Component } from 'react';
import './css/Footer.css'; // Make sure to create a Header.css file with your styles

class Footer extends Component {
  render() {
    return (
      <footer className="site-footer">
      <div className="container">
        <nav className="footer-nav">
        <a href="/" className="nav-link">Home</a>
          <a href="/Training" className="nav-link">Training</a>
          <a href="/programs" className="nav-link">Programs</a>
          <a href="/Research" className="nav-link">Research</a>
          <a href="/About" className="nav-link">About</a>
          <a href="/Providers" className="nav-link">Providers</a>
          <a href="/Contact" className="nav-link">Contact</a>
        </nav>
        <div className="copyright">
          <p>&copy; {new Date().getFullYear()} Cognitive Response Therapy. All rights reserved.</p>
        </div>
      </div>
    </footer>
    );
  }
}

export default Footer;
