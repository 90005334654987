import logo from './logo.svg';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import React, { Component } from 'react';
import Home from './Home';
import Training from './Training';
import Research from './Research';
import Programs from './Programs';
import About from './About';
import Contact from './Contact';
import Glossary from './Glossary';
import Providers from './Providers';
import './css/main.css';

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}
	//<Route exact path='/preview' render={(props) => <Home {...props} preview={true} />} />
	render() {
		return (
			<div className="App">
				<BrowserRouter>
					<Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Training" element={<Training />} />
          <Route path="/Research" element={<Research />} />
          <Route path="/Programs" element={<Programs />} />
          <Route path="/About" element={<About />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Providers" element={<Providers />} />
          <Route path="/Glossary" element={<Glossary />} />

          </Routes>
        </BrowserRouter>
      </div>
    )
    }
  }

export default App;
