// About.js

import React from 'react';
import Header from './Header'; // assuming Header is the navigation bar component
import Footer from './Footer';
import './css/About.css'; // make sure the CSS is linked correctly
import { Link } from 'react-router-dom';

class About extends React.Component {
  render() {
    return (
      <div>
        <Header />
        <div className="title-container">
          <h1 className="research-title"><u className="underline">Where This</u> All Came From</h1>
          <p className="training-intro">
            Cognitive Response Therapy was born out of a now 25 year passionate journey of Elliot Barnett. Through years of <Link to="/Research">research</Link> and collaborations with the other incredible researchers and mental health practitioners, a new approach emerged that is positioned to positively disrupt the mental health industry. 
            But, there is more to do! It will take more practitioners and providers getting trained in this approach to make the impact that is needed.
          </p>
        </div>
        <div className="workshop-container">
          <section className="workshop-box">
            <h2 className="workshop-header">Cognitive Response Therapy Introduction</h2>
            <div className="workshop-details">
              <iframe
                title="Introduction Video"
                className="float-video"
                src="https://player.vimeo.com/video/884585227"
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
                mozallowfullscreen="true"
                webkitallowfullscreen="true">
              </iframe>
              <p>
                Elliot Barnett is an inspirational speaker, a cognitive science researcher, an entrepreneur, an author, and a technology innovator. Elliot’s unique combination of passions has enabled him to be positively disruptive in both the technology and mental health industries. Elliot spent over 20 years studying, researching, and developing in the cognitive sciences, with 15 years of formalized research studies. This work culminated in the identification of three scientific theories that empowered a formalized therapeutic approach, Cognitive Response Therapy, that has been adopted by licensed practitioners. He used the intellectual property to create mental health programs such as Mental Fitness Training and the ARAEt framework and was the first to combine the Agile/Scrum technology framework with a mental health program at My Core Insights. Most recently, Elliot developed a social, e-learning platform for mental wellness at Our Mindset. 
                Elliot is passionate about making empowering mental health information accessible to anyone that wants to help themselves or help others. The science and technology he has developed is making that possibility a reality.
              </p>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    );
  }
}

export default About;
