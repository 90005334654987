// Training.js

import React from 'react';
import Header from './Header'; // assuming Header is the navigation bar component
import Footer from './Footer';
import './css/Training.css'; // make sure the CSS is linked correctly

class About extends React.Component {
  render() {
    return (
      <div>
        <Header />
        <main className="training-content">
        <div className="title-container">
          <h1 className="training-header">Coming Soon</h1>
          <p className="training-intro">
          We are rolling out new certification and training programs for mental health professionals and coaches. As we collect the names and information of those that have gone through the necessary training to be considered CRT trained, we will provide a way for you to find a provider to work with.
          </p>
                    </div>
        </main>
        <Footer />
      </div>
    );
  }
}

export default About;
