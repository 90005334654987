import React, { Component } from 'react';
import Header from './Header'; // Import the Header component
import './css/Research.css'; // Make sure to create a Research.css file with your styles
import Footer from './Footer';

class Programs extends Component {
  render() {
    return (
      <div>
        <Header />
        <div className="title-container">
        <h1 className="research-title"><u className="underline">CRT</u> in Action!</h1>
          <p className="training-intro">
          Cognitive Response Therapy has been used as the basis for many programs, empowering people to live a better, more effective life. Below are some examples of the programs that are at least partially if not entirely based on CRT as well as brief mentions of areas CRT has been heavily demonstrated to be effective. The underlying science that empowers CRT is incredibly flexible and extensible, enabling mental health practitioners and coaches to leverage CRT to impact their clients in innovative ways.
          </p>
        </div>
        {/* Repeat this section for each research study */}
        <section className="research-study">
          <h2>Mental Fitness Training</h2>
          <p>
          Mental Fitness Training is the longest running program that is based on CRT, launched back in 2017. Mental Fitness Training has been delivered through one on one training, group workshops, and through a web platform at <a href="https://mycoreinsights.com">My Core Insights</a>. The focus of this program is bringing people through a phased journey towards living what is referred to as their Optimal Life.
          </p>
        </section>
        <section className="research-study">
          <h2>The ARAEt Framework</h2>
          <p>
          The ARAEt framework and approach is the newest program leveraging CRT. ARAEt is an acronym for Awareness Management, Response Management, Action Management, Effort Management, and Time Management. ARAEt was created to address the challenge people face in time management and goal setting and achievement. It recognizes that a person has to address foundational challenges first before they are able to be effective in what is typically taught in popular self-help books.
          </p>
        </section>
        <section className="research-study">
          <h2>Corporate Programs</h2>
          <p>
          Corporate consultants have implemented programs based on CRT, recognizing that in order to maximize business effectiveness, you have to optimize individual well being at scale. Employee programs based on CRT have been hugely successful in addressing employee well being within organizations.
          </p>
        </section>
        
        <section className="research-study">
          <h2>Stress and Anxiety Disorders</h2>
          <p>
          CRT has been the basis for approaches to address issues people face with stress and anxiety. Following the 6 phases of CRT provides a powerful approach mental health practitioners have used successfully in helping people overcome their challenges in dealing with stress and anxiety.          </p>
        </section>
        <section className="research-study">
          <h2>Addiction and Compulsions</h2>
          <p>
          CRT has been demonstrated to be a powerful approach to help people overcome addictive and compulsive cycles. The focus on Predictive Response Cycles and mindfulness practices makes CRT exceptionally powerful in helping to address these challenges.
          </p>
                  </section>
                  <Footer />
      </div>
    );
  }
}

export default Programs;
